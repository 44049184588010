import React from 'react'
import { graphql } from 'gatsby'
import {
  Image,
  Header,
  Modal,
  Icon,
  Segment,
  List,
  Grid,
  Table,
  Button,
} from 'semantic-ui-react'
import Img from 'gatsby-image'
import Carousel from 'nuka-carousel'
import { ContactForm, EnquiryForm } from '@ballycastle-accommodation/shared-ui'

import Layout from '../components/layout'

const IndexPage = (props) => (
  <Layout>
    <Header as="h2" size="medium" id="gallery" content="" />

    <Carousel
      autoplay="false"
      wrapAround="true"
      heightMode="current"
      renderCenterLeftControls={({ previousSlide }) => (
        <Button basic circular icon size="mini" onClick={previousSlide}>
          <Icon name="chevron left" />
        </Button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <Button basic circular icon size="mini" onClick={nextSlide}>
          <Icon name="chevron right" />
        </Button>
      )}
    >
      <Img fluid={props.data.imageExteriorNew.childImageSharp.fluid} />
      <Img fluid={props.data.imageYardCoastView.childImageSharp.fluid} />
      <Img fluid={props.data.imageCoast.childImageSharp.fluid} />
      <Img fluid={props.data.imageFieldsView.childImageSharp.fluid} />
      <Img fluid={props.data.imageCoastView.childImageSharp.fluid} />
      <Img fluid={props.data.imageWoodenBench.childImageSharp.fluid} />
      <Img fluid={props.data.imageLoungeDiningRoom.childImageSharp.fluid} />
      <Img fluid={props.data.imageLoungeDiningRoomFire.childImageSharp.fluid} />
      <Img fluid={props.data.imageHotWaterBottles.childImageSharp.fluid} />
      <Img
        fluid={
          props.data.imageTeaCupTravelBooksWickerChair.childImageSharp.fluid
        }
      />
      <Img fluid={props.data.imageLoungeChairs.childImageSharp.fluid} />
      <Img fluid={props.data.imageLoungeSofa.childImageSharp.fluid} />
      <Img
        fluid={props.data.imageDownstairsTwinBedroom.childImageSharp.fluid}
      />
      <Img
        fluid={props.data.imageUpstairsDoubleBedroom.childImageSharp.fluid}
      />
      <Img
        fluid={props.data.imageUpstairsMasterBedroom.childImageSharp.fluid}
      />
      <Img
        fluid={props.data.imageUpstairsMasterBedroomLeft.childImageSharp.fluid}
      />
      <Img
        fluid={props.data.imageUpstairsMasterBedroomRight.childImageSharp.fluid}
      />
      <Img fluid={props.data.imageBathroom.childImageSharp.fluid} />
      <Img fluid={props.data.imageWickerChair.childImageSharp.fluid} />
      <Img
        fluid={props.data.imageLoungeDiningRoomTable.childImageSharp.fluid}
      />
      <Img fluid={props.data.imageSideboard.childImageSharp.fluid} />
      <Img fluid={props.data.imageKitchenLeft.childImageSharp.fluid} />
      <Img fluid={props.data.imageKitchenRight.childImageSharp.fluid} />
      <Img fluid={props.data.imageRockingChair.childImageSharp.fluid} />
    </Carousel>

    <Header as="h2" size="medium" id="home" content="Home" />
    <p>
      Glenaan Cottage is the complete getaway. Ideal for writers, artists,
      walkers and adventurers.
    </p>
    <p>
      This beautifully restored whitewashed farmhouse, built high in the Glens
      early in the last century, offers breathtaking views to the village of
      Cushendall and across the rolling hills to Scotland and the Mull of
      Kintyre. The quaint interior is finished to an excellent standard. Outside
      the cottage there is a large open area with small patio. Traditional Irish
      country life at its best!
    </p>

    <Header as="h2" size="medium" id="enquire" content="Enquire" />

    <p>
      Contact us directly for our guaranteed best rates using the form below:
    </p>

    <EnquiryForm />

    <Header as="h2" size="medium" id="explore" content="Explore" />

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Glenaan"
            title="Glenaan"
            fluid={props.data.imageGlenaan.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageGlenaan.childImageSharp.fluid} />
    </Modal>

    <p>
      The Antrim Coast and Glens was only designated as an{' '}
      <a
        href="http://antrimcoastandglensaonb.ccght.org"
        title="Antrim Coast and Glens Area of Outstanding
      Natural Beauty"
        target="_blank"
        rel="noopener noreferrer"
      >
        Area of Outstanding Natural Beauty
      </a>{' '}
      in 1988, but its magnificent natural splendours have been touching the
      souls of those who travel here since time immemorial.
    </p>

    <p>
      Glenaan, also known as the Fuchsia Glen, lies between Glenballyeamon and
      Glendun and runs into Glencorp on the outskirts of Cushendall.
    </p>

    <p>
      Ossian's Grave is Glenaan's most famous site, steeped in history and
      traditionally thought to be the burial place of legendary warrior-poet,
      Ossian, son of Fionn McCumhall (Finn MacCool). This megalithic court cairn
      sits on a hillside in the townland of Lubitavish, 10 minutes' walk from
      our cottage. It also caught the imagination of poet John Hewitt who was
      himself commemorated with a stone cairn in 1989 in the same field.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="left" size="small" rounded>
          <Img
            alt="Curfew Tower, Cushendall"
            title="Curfew Tower, Cushendall"
            fluid={props.data.imageCurfewTowerCushendall.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img
        fluid={props.data.imageCurfewTowerCushendall.childImageSharp.fluid}
      />
    </Modal>

    <p>
      Nearby in Cushendall village is considered by locals to be the 'Capital of
      the Glens' and has a rich history that reaches back through the centuries.
    </p>

    <p>
      The Curfew Tower in the centre of the village was built in 1817 as a
      prison by former landlord of the town, Francis Turnley. Previously the
      village had passed through several owners, including the renowned clan
      chief Sorley Boy McDonnell, the Hollow Sword Blade Company of London and
      later a Dr. Richardson, who had even changed its name to Newtown Glens.
    </p>

    <p>
      The Glens of Antrim Historical Society at The Old Schoolhouse in
      Cushendall is a treasure trove of information for those curious to know
      more about the area, with an{' '}
      <a
        href="https://antrimhistory.net"
        title="The Glens of Antrim Historical Society"
        target="_blank"
        rel="noopener noreferrer"
      >
        excellent website
      </a>{' '}
      and numerous publications to help you unravel the Glens' mysteries.
    </p>

    <p>
      There's plenty to do and see all year round in Cushendall with a hotel,
      restaurants, cafes and a number of lively pubs where you can hear live
      traditional music.
    </p>

    <p>
      Why not head outdoors for nine holes at{' '}
      <a
        href="https://www.cushendallgolfclub.com"
        title="Cushendall Golf Club"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cushendall Golf Club
      </a>{' '}
      or take to the waters of Red Bay with{' '}
      <a
        href="Cushendall Sailing & Boating Club"
        title="http://www.csbc.co.uk"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cushendall Sailing & Boating Club
      </a>
      ? You might even be lucky enough to witness the world's fastest field
      sport, hurling, in action at the local{' '}
      <a
        href="http://www.ruairiog.com"
        title="Ruairí Óg GAA Club"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ruairí Óg GAA Club
      </a>
      .
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Cushendall, Beach and Lurig Mountian"
            title="Cushendall, Beach and Lurig Mountian"
            fluid={
              props.data.imageCushendallLurigMountain.childImageSharp.fluid
            }
          />
        </Image>
      }
    >
      <Img
        fluid={props.data.imageCushendallLurigMountain.childImageSharp.fluid}
      />
    </Modal>

    <p>
      The Heart of the Glens festival takes place annually over nine days in
      August, packing the streets with parades, concerts and a plethora of
      events celebrating the Glens' rich heritage. There's even a fun run that
      traverses the slopes of nearby Lurig Mountain.
    </p>

    <p>
      A short walk from the village will take you to Cushendall Beach where you
      can take in the spectacular views across the Sea of Moyle to the Mull of
      Kintyre on the Scottish coast which is just over 20 miles away.
    </p>

    <p>
      Continue further north by foot on the cliff path to arrive at the ruins of
      Layde Church and Graveyard which dates back to 1306, although the site
      itself may have been in use since Neolithic times.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="left" size="small" rounded>
          <Img
            alt="Cushendun Beach"
            title="Cushendun Beach"
            fluid={props.data.imageCushendunBeach.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageCushendunBeach.childImageSharp.fluid} />
    </Modal>

    <p>
      The small village of Cushendun lies five miles north of Cushendall. It has
      a sheltered harbour and lies at the mouth of the River Dun and Glendun. It
      grew up round the terminus of the Cushendun to Dunaverty ferry which ran
      almost daily between the coast of Kintyre and North Antrim up until about
      1840.
    </p>

    <p>
      Ronald McNeill, who later became Lord Cushendun improved the village
      greatly by acquiring the services of the celebrated Welsh Architect,
      Clough Williams-Ellis, who designed the iconic bridge and two groups of
      houses in the village, known locally as the 'Maud Cottages', built to
      mimic the Cornish homeland of McNeill's wife.
    </p>

    <p>
      The National Trust are the present owners of the village and much of its
      surrounding land, including a beautiful sandy beach. Game of Thrones fans
      might enjoy exploring Cushendun's Red Caves, which were featured in the
      show.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Torr Head"
            title="Torr Head"
            fluid={props.data.imageTorrHead.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageTorrHead.childImageSharp.fluid} />
    </Modal>

    <p>
      Further north again, Torr Head juts out from the rugged coastline,
      accessible by a narrow, winding, rollercoaster of a road that is well
      worth navigating to take in the unparalleled views of Scotland at its
      closest point to Ireland.
    </p>

    <p>
      As the coast turns westward Murlough Bay delivers more incredible vistas,
      with an idyllic seasonally sandy beach that is one of the best kept
      secrets of the Glens. Higher up the slopes you'll find a monument to Irish
      patriot and poet, Sir Roger Casement.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="left" size="small" rounded>
          <Img
            alt="Murlough Bay, Game of Thrones' Slavers Bay"
            title="Murlough Bay, Game of Thrones' Slavers Bay"
            fluid={props.data.imageMurloughBay.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageMurloughBay.childImageSharp.fluid} />
    </Modal>

    <p>
      Excellent walking routes connect Murlough to Fair Head, whose cliff face
      rises 600ft above sea level, offering some incredible rock climbing and
      panoramic views to Scotland, Rathlin Island, Ballycastle, Kinbane Head and
      beyond. There are several small lakes atop the headland, one of which
      contains a manmade Iron Age island or crannog.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Loughareema, The Vanishing Lake"
            title="Loughareema, The Vanishing Lake"
            fluid={props.data.imageVanishingLake.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageVanishingLake.childImageSharp.fluid} />
    </Modal>

    <p>
      The direct road from Cushendall to Ballycastle is a popular cycling route
      that will take you over the impressive Glendun Viaduct, through
      Ballypatrick Forest and past Loughaveema, the vanishing lake, with its
      dramatically fluctuating water levels and haunted bridge.
    </p>

    <p>
      Located at the foot of Knocklayde Mountain and Glentaise, the most
      northerly of the nine Glens of Antrim, Ballycastle is a thriving market
      town by the sea with a glorious mile long golden strand. Rathlin Island
      can be accessed via regular ferry crossing from the harbour.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="left" size="small" rounded>
          <Img
            alt="Children of Lir Sculpture, Ballycastle"
            title="Children of Lir Sculpture, Ballycastle"
            fluid={
              props.data.imageBallycastleChildrenOfLirSculpture.childImageSharp
                .fluid
            }
          />
        </Image>
      }
    >
      <Img
        fluid={
          props.data.imageBallycastleChildrenOfLirSculpture.childImageSharp
            .fluid
        }
      />
    </Modal>

    <p>
      The town plays host the Ireland's oldest fair, the Lammas Fair, every year
      on the last Monday and Tuesday of August as thousands pack the streets
      from far and wide.
    </p>

    <p>
      Ballycastle Tennis, Golf and Bowling clubs feature prominently along the
      seafront along with a great selection of pubs, cafes and restaurants that
      follow the main route through the town.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Giant's Causeway"
            title="Giant's Causeway"
            fluid={props.data.imageGiantsCauseway.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageGiantsCauseway.childImageSharp.fluid} />
    </Modal>

    <p>
      Beyond Ballycastle, the Causeway Coast unfurls to reveal its many
      treasures that include Kinbane Castle, Whitepark Bay, Ballintoy Harbour,
      Dunluce Castle, Bushmills Distillery and the Giant's Causeway World
      Heritage Site.
    </p>

    <p>
      Heading south from Cushendall past Red Bay Castle will take you to
      Waterfoot, a small village at the mouth of Glenariff – the Queen of the
      Glens. Each July Waterfoot hosts the annual{' '}
      <a
        href="http://feisnangleann.com"
        title="Féis na nGleann"
        target="_blank"
        rel="noopener noreferrer"
      >
        Féis na nGleann
      </a>
      , a festival celebrating the Irish language, traditions, songs, music,
      games and past-times.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="left" size="small" rounded>
          <Img
            alt="Glenariff Forest Park"
            title="Glenariff Forest Park"
            fluid={props.data.imageGlenarrifForestPark.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageGlenarrifForestPark.childImageSharp.fluid} />
    </Modal>

    <p>
      The jewel in Glenariff's crown is the{' '}
      <a
        href="https://discovernorthernireland.com/Glenariff-Forest-Park-Glenariffe-Ballymena-P2825/?s=DAF3FF469511F974834DB54CEEE9A41CD464A838"
        title="Glenariff Forest Park"
        target="_blank"
        rel="noopener noreferrer"
      >
        Glenariff Forest Park
      </a>{' '}
      with its truly magnificent waterfalls and trails that cater to everyone
      from the casual walker to the most spirited adventurer. The park is open
      to the public year-round and offers many outdoor activities including
      walking, horse riding and touring. It has picnic and barbeque areas as
      well as a tea house.
    </p>

    <p>
      Glenariff also marks the end (or beginning) of the{' '}
      <a
        href="http://www.walkni.com/walks/187/moyle-way/"
        title="Moyle Way"
        target="_blank"
        rel="noopener noreferrer"
      >
        Moyle Way
      </a>{' '}
      , a challenging 26 mile, two-day walk that explores the northern-most
      Glens of Antrim. Following a mixture of forest tracks and remote upland
      moor, the route includes a visit to the slopes of Trostan, Antrim's
      highest summit at 550m.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Garron Point"
            title="Garron Point"
            fluid={props.data.imageGarronPoint.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageGarronPoint.childImageSharp.fluid} />
    </Modal>

    <p>
      Further south on the Coast Road at Garron Point is the White Lady or
      Cloghastucan, a natural limestone sculpture resembling a Victorian lady.
      It stands proudly guarding the entrance to St Killian's College (formerly
      St MacNissi's).
    </p>

    <Header as="h2" size="medium" id="contact" content="Contact" />
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4565.901765256052!2d-6.103249!3d55.09394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7bff98ed190d2355!2sGlenaan+Self+Catering+Cottage!5e0!3m2!1sen!2suk!4v1550350505050"
      width="100%"
      height="450"
      title="Google Maps"
      allowFullScreen
    />

    <p>
      For any enquiries or to make a booking please contact Paul Jameson on:
    </p>

    <Grid columns={2} stackable padded="vertically">
      <Grid.Row>
        <Grid.Column verticalAlign="middle">
          <Table collapsing compact="very" fixed unstackable basic="very">
            <Table.Row>
              <Table.Cell>
                <Icon circular name="mobile alternate" />
              </Table.Cell>
              <Table.Cell>
                <a title="Mobile Phone Number" href="tel:+44-77-3488-6138">
                  +44 (0)77 3488 6138
                </a>
              </Table.Cell>
            </Table.Row>
          </Table>
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <Table collapsing compact="very" fixed unstackable basic="very">
            <Table.Row title="Postal Address">
              <Table.Cell>
                <Icon circular name="mail outline" />
              </Table.Cell>
              <Table.Cell>
                <List>
                  <li>31 Glenann Road</li>
                  <li>Cushendall</li>
                  <li>Ballymena</li>
                  <li>Co. Antrim</li>
                  <li>Northern Ireland</li>
                  <li>BT44 0TG</li>
                </List>
              </Table.Cell>
            </Table.Row>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <p>Or send us an email using the form below:</p>

    <ContactForm />

    <Segment inverted color="grey">
      <Grid relaxed columns={2}>
        <Grid.Row>
          <Grid.Column stretched width={1}>
            <Grid.Row>
              <a
                title="Facebook"
                href="http://www.facebook.com/pages/Hillsea-Bed-Breakfast/219252024758236"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="large" inverted name="facebook" />
              </a>
            </Grid.Row>
            <Grid.Row>
              <a
                title="Twitter"
                href="http://twitter.com/hillseabandb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="large" inverted name="twitter" />
              </a>
            </Grid.Row>
            <Grid.Row>
              <a
                title="Flickr"
                href="http://www.flickr.com/photos/hillseabandb/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="large" inverted name="flickr" />
              </a>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <a
              title="Discover Northern Ireland"
              href="https://discovernorthernireland.com/Glenaan-Cottage-Cushendall-P7519/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image size="tiny">
                <Img fixed={props.data.imageTNILogo.childImageSharp.fixed} />
              </Image>
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment basic textAlign="center">
      <List link horizontal divided>
        <List.Item as="a" href={`${process.env.HUB_URL}`}>
          Check out our other properties...
        </List.Item>
        <List.Item>Privacy policy</List.Item>
        <List.Item>
          Copyright © {new Date().getFullYear()}. All Rights Reserved.
        </List.Item>
        <List.Item>Built by James McDonnell</List.Item>
      </List>
    </Segment>
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 6000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    imageTNILogo: file(
      relativePath: { eq: "tourism_northern_ireland_logo.png" }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    imageGlenaan: file(
      relativePath: { eq: "attractions/Glens-Of-Antrim-Glenaan.JPG" }
    ) {
      ...fluidImage
    }
    imageCurfewTowerCushendall: file(
      relativePath: { eq: "attractions/Curfew-Tower-Cushendall.JPG" }
    ) {
      ...fluidImage
    }
    imageCushendallLurigMountain: file(
      relativePath: { eq: "attractions/Cushendall-Lurig-Mountain.JPG" }
    ) {
      ...fluidImage
    }
    imageCushendunBeach: file(
      relativePath: { eq: "attractions/Cushendun-Beach.JPG" }
    ) {
      ...fluidImage
    }
    imageMurloughBay: file(
      relativePath: {
        eq: "attractions/Murlough-Bay-Game-of-Thrones-Slavers-Bay.JPG"
      }
    ) {
      ...fluidImage
    }
    imageTorrHead: file(relativePath: { eq: "attractions/Torr-Head.JPG" }) {
      ...fluidImage
    }
    imageVanishingLake: file(
      relativePath: { eq: "attractions/Loughareema-The-Vanishing-Lake.JPG" }
    ) {
      ...fluidImage
    }
    imageGiantsCauseway: file(
      relativePath: { eq: "attractions/Giant's-Causeway.JPG" }
    ) {
      ...fluidImage
    }
    imageBallycastleChildrenOfLirSculpture: file(
      relativePath: {
        eq: "attractions/Children-Of-Lir-Swan-Sculpture-Ballycastle.JPG"
      }
    ) {
      ...fluidImage
    }
    imageGlenarrifForestPark: file(
      relativePath: { eq: "attractions/Glenariff-Forest-Park-Waterfall.JPG" }
    ) {
      ...fluidImage
    }
    imageGarronPoint: file(
      relativePath: { eq: "attractions/Garron-Point.JPG" }
    ) {
      ...fluidImage
    }
    imageExteriorNew: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-Exterior.JPG" }
    ) {
      ...fluidImage
    }
    imageCoast: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-Exterior-Coast.JPG" }
    ) {
      ...fluidImage
    }
    imageFireplace: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-Lounge-Dining-Room-Fire.JPG"
      }
    ) {
      ...fluidImage
    }
    imageCoastView: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Coast-View.jpg" }
    ) {
      ...fluidImage
    }
    imageBathroom: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Bathroom.jpg" }
    ) {
      ...fluidImage
    }
    imageLoungeChairs: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Lounge-Chairs.jpg" }
    ) {
      ...fluidImage
    }
    imageLoungeSofa: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Lounge-Sofa.jpg" }
    ) {
      ...fluidImage
    }
    imageRockingChair: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Rocking-Chair.jpg" }
    ) {
      ...fluidImage
    }
    imageDownstairsTwinBedroom: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Downstairs-Twin-Bedroom.jpg"
      }
    ) {
      ...fluidImage
    }
    imageFieldsView: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Fields-View.jpg" }
    ) {
      ...fluidImage
    }
    imageHotWaterBottles: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Hot-Water-Bottles.jpg" }
    ) {
      ...fluidImage
    }
    imageKitchenLeft: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Kitchen-Left.jpg" }
    ) {
      ...fluidImage
    }
    imageKitchenRight: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Kitchen-Right.jpg" }
    ) {
      ...fluidImage
    }
    imageLoungeDiningRoom: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Lounge-Dining-Room.jpg"
      }
    ) {
      ...fluidImage
    }
    imageLoungeDiningRoomFire: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Lounge-Dining-Room-Fire.jpg"
      }
    ) {
      ...fluidImage
    }
    imageLoungeDiningRoomTable: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Lounge-Dining-Room-Table.jpg"
      }
    ) {
      ...fluidImage
    }
    imageSideboard: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Sideboard.jpg" }
    ) {
      ...fluidImage
    }
    imageTeaCupTravelBooksWickerChair: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Tea-Cup-Travel-Books-Wicker-Chair.jpg"
      }
    ) {
      ...fluidImage
    }
    imageUpstairsDoubleBedroom: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Upstairs-Double-Bedroom.jpg"
      }
    ) {
      ...fluidImage
    }
    imageUpstairsMasterBedroom: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Upstairs-Master-Bedroom.jpg"
      }
    ) {
      ...fluidImage
    }
    imageUpstairsMasterBedroomLeft: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Upstairs-Master-Bedroom-Left.jpg"
      }
    ) {
      ...fluidImage
    }
    imageUpstairsMasterBedroomRight: file(
      relativePath: {
        eq: "glenaan/Glenaan-Cottage-2022-Upstairs-Master-Bedroom-Right.jpg"
      }
    ) {
      ...fluidImage
    }
    imageWickerChair: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Wicker-Chair.jpg" }
    ) {
      ...fluidImage
    }
    imageWoodenBench: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Wooden-Bench.jpg" }
    ) {
      ...fluidImage
    }
    imageYardCoastView: file(
      relativePath: { eq: "glenaan/Glenaan-Cottage-2022-Yard-Coast-View.jpg" }
    ) {
      ...fluidImage
    }
  }
`
